@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.budget-progress {
  margin: 10px 0;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-filled {
  height: 20px;
  background-color: #76b5c5;
  text-align: right;
  padding-right: 5px;
  line-height: 20px;
  color: white;
  border-radius: 5px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #f0f2f5; /* Light gray background */
  font-family: 'Arial', sans-serif;
}

.login-container h1 {
  margin-bottom: 20px;
  color: #333;
}

.login-button {
  background-color: #4285F4; /* Google blue */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #357ae8; /* Darker Google blue */
}

.navbar {
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px; /* Horizontal padding */
  height: 60px; /* Fixed height */
}

.app-title {
  
  margin: 0;
}

.logout-button {
  background-color: #f44336; /* Red color */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #d32f2f; /* Darker red */
}

.user-info {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between name and button */
}

.user-info span {
  font-size: 1rem; 
  color: #333; 
}
